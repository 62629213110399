//
//
//
//
//
//
//
//
//
//
//
//

    export default
    {
    name: "blockcalltoaction",
    props: {
        content: Object
    },
}
